import {Badge} from "@instructure/ui-badge";
import {Popover} from "@instructure/ui-popover";
import {IconButton} from "@instructure/ui-buttons";
import {IconAlertsLine, IconExternalLinkLine} from "@instructure/ui-icons";
import React, {useState} from "react";
import {View} from "@instructure/ui-view";
import {List} from "@instructure/ui-list";
import {Heading} from "@instructure/ui-heading";
import {Link} from "@instructure/ui-link";

export function NewsComponent() {
    
    // To generate a new ID: `uuidgen | tr '[:upper:]' '[:lower:]' | cut -c 1-8`
    const items = [
        {
            id: '4e2b9093',
            content: '📋 We would be grateful for any feedback you have on this tool.',
            link: {
                href: 'https://forms.gle/96Cx1Dzeos6J4wKP7',
                name: 'Survey link.'
            }
        }
    ]
    
    const [seen, setSeen] = useState(() => {
        try {
            const storage = localStorage.getItem("seen");
            if (storage) {
                return JSON.parse(storage)
            }
        } catch (error) {
            console.warn(`Failed to load seen items: ${error}`)
        }
        return [];
    })
    
    const markSeen = () => {
        const ids = items.map(item => item.id);
        const storage = JSON.stringify(ids)
        try {
            localStorage.setItem("seen", storage)
        } catch (error) {
            console.warn(`Fail to save seen items: ${storage}`)
        }
        setSeen(ids)
    }
    
    const unSeen = items.filter(item => !seen.includes(item.id))
    const hasUnSeen = unSeen.length > 0
    
    return <Popover on='click' shouldContainFocus={false} shouldFocusContentOnTriggerBlur 
                    onHideContent={markSeen}
                    renderTrigger={
        <IconButton withBackground={false} withBorder={false} screenReaderLabel="News">
            <Badge count={unSeen.length} margin={hasUnSeen?"0 large 0 0":"0"} pulse={hasUnSeen}>
                <IconAlertsLine/>
            </Badge>
        </IconButton>
    }>
        <View padding="small" display="block" as="div" id="tip" maxWidth='25em'>
            <Heading level='h3' border='bottom'>Updates</Heading>
            <List delimiter='none' isUnstyled itemSpacing='small'>
                {items.map((item)=> <List.Item key={item.id}>
                    {item.content}
                    {item.link && <>&nbsp;<Link target='_blank' href={item.link.href} renderIcon={IconExternalLinkLine} iconPlacement='end'>{item.link.name}</Link></> }
                </List.Item>)}
            </List>
        </View>
    </Popover>
}