import React from "react"
import PropTypes from "prop-types"

import {View} from "@instructure/ui-view"
import {Button, IconButton} from "@instructure/ui-buttons"
import {IFrame} from "./Iframe";
import {Flex} from "@instructure/ui-flex";
import {Link} from "@instructure/ui-link";
import {
    IconAddLine,
    IconArrowOpenEndLine,
    IconArrowOpenStartLine,
    IconMoreLine,
    IconQuestionLine
} from "@instructure/ui-icons";
import {Heading} from "@instructure/ui-heading";
import {uid} from '@instructure/uid'
import SnippetEditor from "./SnippetEditor";
import {ToggleGroup} from "@instructure/ui-toggle-details";
import {List} from "@instructure/ui-list";
import {TruncateText} from "@instructure/ui-truncate-text";
import {DrawerLayout} from "@instructure/ui-drawer-layout";
import {Menu} from "@instructure/ui-menu";
import {canvas, shared} from "./predefined"
import {NewsComponent} from "./NewsComponent";


class Snippets extends React.Component {

    static propTypes = {
        selection: PropTypes.string,
        // The LTI return URL.
        returnUrl: PropTypes.string.isRequired,
        data: PropTypes.string.isRequired,
        css: PropTypes.string,
        cssVariables: PropTypes.string,
        development: PropTypes.bool.isRequired,
        /**
         * The lookup for loading custom snippets for.
         */
        domain: PropTypes.string,
        /**
         * Should we hide the predefined snippets.
         */
        hidePredefined: PropTypes.bool
    }

    static defaultProps = {
        selection: 'Selected text',
        css: 'https://du11hjcvx0uqb.cloudfront.net/br/dist/brandable_css/responsive_layout_normal_contrast/bundles/common-03cbb0ce89.css',
        cssVariables: 'https://du11hjcvx0uqb.cloudfront.net/br/dist/brandable_css/645beea198665f1487136eff16533cde/variables-8391c84da435c9cfceea2b2b3317ff66.css',
        development: false,
        hidePredefined: false
    }

    state = {
        newHtml: "",
        modalOpen: false,
        modelEdit: false,
        modalItem: {},
        contentItems: "",
        // The items loaded from local storage
        items: {
        },
        // The items loaded from server
        additionalItems: [],
        message: null,
        // The name of the snippet being rendered.
        name: "",
        // The actual HTML snippet to be rendered
        html: "",
        // Is the draw open?
        draw: true
    }

    constructor(props, context) {
        super(props, context)
        this.formRef = React.createRef()
        this.canvas = canvas.reduce((prev, val) => {
            prev[val.id] = val
            return prev
        }, {})
        this.shared = shared.reduce((prev, val) => {
            prev[val.id] = val
            return prev
        }, {})
    }

    componentDidMount() {
        const loaded = localStorage.getItem("snippets")
        if (loaded) {
            this.setState({
                items: JSON.parse(loaded)
            })
        } else {
            const id = uid()
            const items = {}
            items[id] = {
                id,
                name: "Example snippet",
                html: "<p>This is an example snippet.</p>\n<p>Currently selected text: $selection</p>"
            }
            this.setState({items})
        }
        this.loadSnippets(this.props.domain)
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.domain !== prevProps.domain) {
            this.loadSnippets(this.props.domain)
        }
    }

    loadSnippets = (domain) => {
        if (domain) {
            fetch(`/data/${domain}.json`)
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw new Error('Bad response, status: '+response.status)
                    }
                })
                .then((json) => {
                    this.setState({additionalItems: json})
                })
                .catch((error) => {
                    console.log(`Failed to load data for ${domain}, reason: ${error}`)
                })
        }
    }

    save = () => {
        localStorage.setItem("snippets", JSON.stringify(this.state.items))
    }

    buildHtml = (item) => {
        const {selection} = this.props
        // Seem to be seeing some errors where randomUUID isn't defined on Edge (when it should be)
        const randomId = (crypto && crypto.randomUUID && crypto.randomUUID()) ||
            (Math.random() + 1).toString(36).substring(4);
        const snippet = item.html
            .replace("$selection", selection)
            // This allows us to insert content that requires unique IDs in the document
            .replaceAll("$randomId", randomId)
        return this.addDataAttribute(snippet, item.id);
    }

    addDataAttribute(snippet, id) {
        const doc = new DOMParser().parseFromString(snippet, "text/html");
        // Put an ID on the containing element.
        if (doc.body.children.length === 1) {
            doc.body.children[0].setAttribute('data-snippet', id)
        }
        // doc.documentElement.setAttribute('data-snippet', id)
        return new XMLSerializer().serializeToString(doc.body)
    }

    makeContentItem = (html) => {
        // Don't return anything if empty
        let item = []
        if (html.length > 0) {
            item.push({
                '@type': 'ContentItem',
                'mediaType': 'text/html',
                'text': html,
                'placementAdvice': {
                    'presentationDocumentTarget': 'embed'
                }
            })
        }
        return {
            '@context': 'http://purl.imsglobal.org/ctx/lti/v1/ContentItem',
            '@graph': item
        }
    }

    toEmpty = (value) => {
        // This is so that we don't put null values into input elements.
        return value !== null && value !== undefined ? value : ''
    }

    onCancelClick = async () => {
        await this.setState({
            contentItems: JSON.stringify(this.makeContentItem(''))
        })
        this.formRef.current.submit()
    }

    onClick = async () => {
        await this.setState({
            contentItems: JSON.stringify(this.makeContentItem(this.state.html))
        })
        this.formRef.current.submit()
    }

    update = (id, name, html) => {
        const items = {
            ...this.state.items,
        }
        items[id] = {id, name, html}
        this.setState({items}, () => this.save())
    }

    delete = (id) => {
        const updated = {...this.state.items}
        delete updated[id]
        this.setState({
            items: updated
        }, this.save)
    }

    renderSnippetList = (items, editable) => {
        return <List margin='x-small small x-small none'>
            {this.renderItem(items, editable)}
        </List>;
    }

    renderItem = (items, editable = false) => {
        return Object.keys(items).map(id => <List.Item key={id}>
                <Flex>
                    <Flex.Item shouldGrow shouldShrink>
                        <Link onClick={() => this.setState({
                            html: this.buildHtml(items[id]),
                            name: items[id].name
                        })}>
                            <TruncateText>{items[id].name}</TruncateText>
                        </Link>
                    </Flex.Item>
                    <Flex.Item align='end'>
                        <Menu
                            trigger={<IconButton screenReaderLabel="More options" withBackground={false} withBorder={false}><IconMoreLine/></IconButton>}>
                            <Menu.Item
                                disabled={!editable}
                                onClick={() => {
                                    this.setState({
                                        modalOpen: true,
                                        modalEdit: true,
                                        modalItem: items[id]
                                    })
                                }}>
                                Edit
                            </Menu.Item>
                            <Menu.Item
                                disabled={!editable}
                                onClick={() => {
                                    if (window.confirm('Delete "' + items[id].name + '" snippet?')) {
                                        this.delete(id);
                                    }
                                }}>
                                Delete
                            </Menu.Item>
                            {this.props.development && <Menu.Item
                                onClick={() => {
                                    console.log(JSON.stringify(items[id]))
                                }}>
                                Export
                            </Menu.Item>
                            }
                        </Menu>
                    </Flex.Item>
                </Flex>
            </List.Item>
        )
    }


    render() {
        const returnUrl = this.props.returnUrl
        const noReturn = !(returnUrl && returnUrl.length > 0)
        const noSelected = this.state.html.length === 0

        // Do we have a selection from the user using the editor, or are we inserting new content
        const hasSelection = this.props.selection === Snippets.defaultProps.selection;

        return <View as='div' height='100%'>
            <form action={returnUrl} method='POST' ref={this.formRef}>
                <input type='hidden' name='lti_version' value='LTI-1p0'/>
                <input type='hidden' name='lti_message_type' value='ContentItemSelection'/>
                <input type='hidden' name='data' value={this.toEmpty(this.props.data)}/>
                <input type='hidden' name='content_items' value={this.toEmpty(this.state.contentItems)}/>
                <input type='hidden' name='lti_msg' value={this.toEmpty(this.state.message)}/>
            </form>

            <DrawerLayout minWidth="0">
                <DrawerLayout.Content label='List of snippets'>
                    <Flex direction="column" height='100%'>
                        <Flex.Item>
                            <View display='inline-block' padding='small'>
                                <IconButton withBackground={false} withBorder={false}
                                            screenReaderLabel='Toggle the snippets draw'
                                            renderTooltipContent={this.state.draw ? 'Close the draw' : 'Open the draw'}
                                            onClick={() => this.setState({draw: !this.state.draw})}>
                                    {this.state.draw ? <IconArrowOpenStartLine/> : <IconArrowOpenEndLine/>}
                                </IconButton>
                            </View>
                            <View display='inline-block'>
                                <Heading margin='small'
                                         level='h4'>
                                    Preview {this.state.name ? ' of "' + this.state.name + '"' : ''}</Heading>
                            </View>
                        </Flex.Item>
                        <Flex.Item align='stretch' shouldGrow shouldShrink>
                            {/*<CodeEditor label='Inserted HTML' language='html' value={this.state.html} readOnly*/}
                            {/*            options={{lineWrapping: true}}/>*/}
                            <IFrame style={{border: 0, width: '100%', height: '95%'}} sandbox='allow-same-origin'
                                    title='HTML Preview'
                                    head={<>
                                        <link href={this.props.cssVariables} rel='stylesheet'/>
                                        <link href={this.props.css} rel='stylesheet'/>
                                    </>}>
                                {/* Some Canvas styles use -12px offsets which generates scrollbars unless we have enough padding */}
                                <div className=".show-content" style={{padding: '15px'}}>
                                    <View as='div' dangerouslySetInnerHTML={{__html: this.state.html}}/>
                                </div>
                            </IFrame>
                        </Flex.Item>
                        <Flex.Item padding='none small small small'>
                            <Flex>
                                <Flex.Item shouldGrow>
                                    <Link renderIcon={IconQuestionLine} margin='0 medium' target='_blank'
                                          href='https://wyelearning.com/support/snippets/'>Help</Link>
                                </Flex.Item>
                                <Flex.Item>
                                    <NewsComponent/>
                                </Flex.Item>
                                <Flex.Item>
                                    <Button onClick={this.onCancelClick} margin='xx-small'
                                            interaction={noReturn ? 'disabled' : 'enabled'}>Cancel</Button>
                                    <Button color='primary' onClick={this.onClick} margin='xx-small'
                                            interaction={noReturn || noSelected ? 'disabled' : 'enabled'}>{hasSelection? 'Insert' : 'Update'}</Button>

                                </Flex.Item>
                            </Flex>
                        </Flex.Item>
                    </Flex>
                </DrawerLayout.Content>

                <DrawerLayout.Tray
                    label="List of snippets"
                    open={this.state.draw}
                    size="small"
                    placement="start"
                    onDismiss={() => {
                        this.setState({draw: false})
                    }}
                >
                    <View as="div" width='300px' height='100%' overflowY='auto'>
                        <ToggleGroup
                            toggleLabel="This is the toggle button label for screenreaders"
                            summary="My Snippets"
                            defaultExpanded
                        >
                            <View display='block'>
                                {this.renderSnippetList(this.state.items, true)}
                                <Button size="small" renderIcon={IconAddLine} margin='none small small small'
                                        withBackground={false} onClick={() => this.setState({
                                    modalOpen: true,
                                    modalEdit: false,
                                    modalItem: {id: uid()}
                                })}>Add Snippet</Button>
                            </View>
                        </ToggleGroup>
                        {this.state.additionalItems.length > 0 && <ToggleGroup
                            toggleLabel="Toggle the display of institutional snippets"
                            summary="Institutional Snippets"
                            defaultExpanded
                        >
                            {this.renderSnippetList(this.state.additionalItems, false)}
                        </ToggleGroup>
                        }
                        {!this.props.hidePredefined &&
                        <ToggleGroup
                            toggleLabel="Toggle the display of shared snippets"
                            summary="Shared Snippets"
                        >
                            {this.renderSnippetList(this.shared, false)}
                        </ToggleGroup>
                        }
                        {!this.props.hidePredefined &&
                        <ToggleGroup
                            toggleLabel="Toggle the display of Canvas snippets"
                            summary="Canvas Snippets"
                        >
                            {this.renderSnippetList(this.canvas, false)}
                        </ToggleGroup>
                        }
                    </View>
                </DrawerLayout.Tray>
            </DrawerLayout>
            {this.state.modalOpen && <SnippetEditor
                edit={this.state.modalEdit}
                close={() => this.setState({modalOpen: false})}
                update={this.update}
                id={this.state.modalItem.id}
                name={this.state.modalItem.name}
                html={this.state.modalItem.html}
            />}
        </View>
    }

}

export default Snippets