// iframe.js

import React, { Component } from 'react'
import { createPortal } from 'react-dom'

/**
 * This is a component that puts the children in an iframe. This is allows us to more safely render HTML.
 * @see https://stackoverflow.com/questions/34743264/how-to-set-iframe-content-of-a-react-component
 */
export class IFrame extends Component {

    constructor(props) {
        super(props)
        this.state = {
            headNode: null,
            bodyNode: null
        }
    }

    updateContentRef = () => {
        this.setState({
            headNode: this.iframeRef?.contentWindow?.document?.head,
            bodyNode: this.iframeRef?.contentWindow?.document?.body
        })
    }

    updateIframeRef = (iframeRef) => {
        this.iframeRef = iframeRef
        // This is needed because Chrome doesn't fire the onload event for an iframe without a src to load
        // https://github.com/whatwg/html/issues/490
        this.updateContentRef()
    }

    render() {
        const { head, children, title, ...props } = this.props
        const { headNode, bodyNode } = this.state
        return (
            // For Firefox support we have to have a 2 step update.
            // First we same the ref of the iframe in the component.
            // Secondly when the iframe is loaded (no longer changing) we lookup the portal references.
            // Otherwise we save the references, then firefox loads in a new document.
            <iframe
                {...props}
                title={title}
                ref={this.updateIframeRef}
                onLoad={this.updateContentRef}
            >
                {headNode && createPortal(head, headNode)}
                {bodyNode && createPortal(children, bodyNode)}
            </iframe>
        )
    }
}