import React from "react"
import PropTypes from "prop-types"
import {Spinner} from '@instructure/ui-spinner'
import ErrorBillBoard from "./ErrorBillBoard"

/**
 * Looks for a one time token in the URL parameters and then attempts to use this to retrieve a JWT token
 * from the LTI launch service. If the token can't be retrieved then is displays a nice error message.
 *
 * Error cases we handle:
 * - no token in the URL
 * - token cannot be retrieved
 */
class LtiTokenRetriever extends React.Component {

  static propTypes = {
    // The URL to the LTI server to get the token from.
    ltiServer: PropTypes.string,
    // Callback that is passed the loaded JWT
    handleData: PropTypes.func.isRequired,
    // The application node to render as long as we're all good.
    children: PropTypes.node.isRequired
  }

  static defaultProps = {
    ltiServer: null
  }

  state = {
    // If we are loading the token at the moment.
    loading: false,
    // Have we tried loading.
    loadingTried: false,
    // A human readable error message
    error: null,
  }

  componentDidMount() {
    if (!this.props.ltiServer) {
      this.setState({error: "No LTI Server to load from"})
    } else {
      if (!this.state.loadingTried) {
        this.setState({ loadingTried: true })
        const id = this.getId()
        if (id) {
            if (id !== 'preview') {
              this.fetchData(id)
            }
        } else {
          this.setState({ error: "No id found to load with" })
        }
      }
    }
  }

  getId = () => {
    const params = new URLSearchParams(window.location.search)
    return params.get('id')
  }

  saveData = (data) => {
    if (data) {
      localStorage.setItem("data", JSON.stringify(data))
    }
  }

  loadData = () => {
    let data = JSON.parse(localStorage.getItem("data"))
    if (data) {
      console.info("Loaded data from local storage.")
    }
    return data
  }

  fetchData = (token) => {
    this.setState({loading: true})
    var url = new URL(this.props.ltiServer + '/token')
    var params = {id: token}
    url.search = new URLSearchParams(params).toString()
    fetch(url, {
      headers: { 'Accept': 'application/json'}
      }
    ).then(response => {
        if (!response.ok) {
          const data = this.loadData()
          if (!data) {
            throw new Error("Failed to load token.")
          }
          return data
        } else {
          return response.json()
        }
      }
    ).then(json => {
      this.props.handleData(json)
      this.saveData(json)
    }).catch(reason => {
      this.setState({error: reason.toString()})
    }).finally(() => {
      this.setState({loading: false})
    })
  }

  render() {
    return (
      <ErrorBillBoard message={this.state.error}>
        {(this.state.loading) ? this.renderLoading() : this.props.children}
      </ErrorBillBoard>
    )
  }

  renderLoading() {
    return <Spinner size="large" margin="large" renderTitle="Loading data..."/>
  }
}

export default LtiTokenRetriever