import React from "react"
import {Modal} from "@instructure/ui-modal";
import {Heading} from "@instructure/ui-heading";
import {FormField, FormFieldGroup} from "@instructure/ui-form-field";
import {ScreenReaderContent} from "@instructure/ui-a11y-content";
import {TextInput} from "@instructure/ui-text-input";
import {Button} from "@instructure/ui-buttons";
import {Text} from "@instructure/ui-text";
import {Flex} from "@instructure/ui-flex";
import {IconWarningLine} from "@instructure/ui-icons";
import {SourceCodeEditor} from "@instructure/ui-source-code-editor";

class SnippetEditor extends React.Component {
    state = {
        name: "",
        html: ""
    }

    // Designed to be re-mounted
    componentDidMount() {
        const {name, html} = this.props
        this.setState({ name, html })
    }

    render() {
        const {id, edit, close} = this.props
        return <Modal open onDismiss={close} size='fullscreen'>
            <Modal.Header>
                <Heading level='h3'>{edit?'Edit':'Add'} Snippet</Heading>
            </Modal.Header>
            <Modal.Body>
                <FormFieldGroup description={<ScreenReaderContent>New Snippet</ScreenReaderContent>}>
                    <TextInput renderLabel='Name' value={this.state.name} onChange={(event, name) => this.setState({name})}/>
                    <FormField id="name" label="HTML">
                        <SourceCodeEditor language='html' 
                                          defaultValue={this.props.html}
                                          onChange={(value) => this.setState({html: value})} 
                                          lineWrapping lineNumbers/>
                    </FormField>
                </FormFieldGroup>
            </Modal.Body>
            <Modal.Footer>
                <Flex width="100%">
                    <Flex.Item shouldGrow padding="none small">
                        <Text weight="light"><IconWarningLine title="Warning"/> This snippet will be stored in your web browser.</Text>
                    </Flex.Item>
                    <Flex.Item>
                        <Button onClick={close} margin='xx-small'>Cancel</Button>
                    </Flex.Item>
                    <Flex.Item>
                        <Button onClick={() => {this.props.update(id, this.state.name, this.state.html); close()}} margin='xx-small' color='primary'>{edit?'Save':'Add'}</Button>
                    </Flex.Item>
                </Flex>
            </Modal.Footer>
        </Modal>
    }
}

export default SnippetEditor