import React from 'react'

import LtiApplyTheme from './LtiApplyTheme'
import LaunchRetriever from './LaunchRetriever'
import Snippets from "./Snippets";

const settings = {
  'https://localhost:3000': {
    'ltiServer': process.env.REACT_APP_LTI_URL
  },
  'https://snippets.wyelearning.com': {
    'ltiServer': 'https://lti.wyelearning.com'
  }
}

class App extends React.Component {
  state = {
    jwt: null,
    needsToken: false,
    error: null,
    brandConfig: null,
    returnUrl: null,
    highContrast: false,
    data: null,
    css: undefined,
    development: process.env.NODE_ENV !== 'production',
    hidePredefined: false
  }

  constructor(props) {
    super(props)
    const origin = window.origin
    if (settings[origin]) {
      this.server = settings[origin].ltiServer
    }
  }

  cssVariableUrl = (data) => {
    // There isn't a variable for the css URL but the json url is in the same place.
    const jsonUrl = this.getProp(data, 'custom_brand_config');
    if (jsonUrl && jsonUrl.endsWith(".json")) {
      return jsonUrl.slice(0, -5) + ".css"
    }

  }

  /**
   * Gets a value from the LTI launch data.
   * @param data The LTI launch data
   * @param prop The property to get
   * @returns {null|*} The first value in the property array or null if not found
   */
  getProp = (data, prop) => {
    if ( data && data[prop]) {
      return data[prop][0]
    }
    return null
  }

  updateData = async (data) => {
    let selection = this.getProp(data, 'custom_selection')
    // If there isn't any selection is sends through the unexpanded variable (although this seems to have changed)
    if (selection === '$com.instructure.Editor.selection' || selection === '') {
      selection = undefined
    }

    return this.setState({
      brandConfig: this.getProp(data, 'custom_brand_config'),
      highContrast: this.getProp(data, 'custom_high_contrast') === 'true',
      selection: selection,
      returnUrl: this.getProp(data, 'content_item_return_url'),
      data: this.getProp(data, 'data'),
      css: this.getProp(data, 'custom_canvas_css'),
      cssVariables: this.cssVariableUrl(data),
      // if there's a development custom variable or we're in development mode.
      development: data.custom_development || this.state.development,
      domain: this.getProp(data, 'custom_canvas_domain'),
      hidePredefined: this.getProp(data, 'custom_hide_predefined') === 'true'
    })
  }

  render() {
    return (
      <LaunchRetriever ltiServer={this.server} handleData={this.updateData}>
        <LtiApplyTheme url={this.state.brandConfig} highContrast={this.state.highContrast}>
          <Snippets
            selection={this.state.selection}
            returnUrl={this.state.returnUrl}
            data={this.state.data}
            css={this.state.css}
            cssVariables={this.state.cssVariables}
            development={this.state.development}
            domain={this.state.domain}
            hidePredefined={this.state.hidePredefined}
          />
        </LtiApplyTheme>
    </LaunchRetriever>
    )
  }
}

export default App
