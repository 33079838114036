import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import App from './App'

Sentry.init({
    dsn: 'https://3209444b9bef4364b5769c784fbf2558@o459966.ingest.sentry.io/5920448',
    integrations: [new Sentry.BrowserTracing()],
    // This is the git SHA
    release: process.env.REACT_APP_GIT_SHA,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

ReactDOM.render(<App />, document.getElementById('app'))
